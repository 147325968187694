.adyen-checkout__card-input__form {
  transition: opacity 0.25s ease-out; }

.adyen-checkout__card__cardNumber {
  max-width: 400px; }

.adyen-checkout__card__cardNumber__input {
  padding: 5px 8px; }

.adyen-checkout__card__exp-date__input--oneclick {
  line-height: 30px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left; }

.adyen-checkout__field--storedCard,
.adyen-checkout__field--expiryDate,
.adyen-checkout__card__holderName {
  margin-bottom: 0; }

.adyen-checkout__card__holderName,
.adyen-checkout__store-details,
.adyen-checkout__card__kcp-authentication,
.adyen-checkout__card__socialSecurityNumber,
.adyen-checkout__installments,
.adyen-checkout__card-input .adyen-checkout__fieldset--billingAddress {
  margin-top: 16px; }

.adyen-checkout__card-input.adyen-checkout__card-input--loading {
  pointer-events: none; }

.adyen-checkout__card__holderName:first-child {
  margin: 0 0 16px; }

/* When holderName is on top & co-exists with an error panel */
.adyen-checkout-error-panel--sr-only + .adyen-checkout__card__holderName,
.adyen-checkout-error-panel + .adyen-checkout__card__holderName {
  margin: 0 0 16px; }

/* Hide card brand icon when cardNumber is in an error state */
.adyen-checkout__field--cardNumber .adyen-checkout__input--error .adyen-checkout__card__cardNumber__brandIcon {
  display: none; }

/* Hide checkmark when cardNumber is in a valid state (only show brand icon) */
.adyen-checkout__field--cardNumber
.adyen-checkout__input--valid:not(.adyen-checkout__card__cardNumber__input--noBrand)
+ .adyen-checkout-input__inline-validation--valid {
  display: none; }

.adyen-checkout__field--securityCode.adyen-checkout__field--error .adyen-checkout__card__cvc__hint,
.adyen-checkout__field--securityCode.adyen-checkout__field--valid .adyen-checkout__card__cvc__hint {
  opacity: 0; }

@keyframes cvcIndicateLocation {
  from {
    opacity: 1; }
  to {
    opacity: 0.3; } }

.adyen-checkout__label--focused .adyen-checkout__field__cvc--front-hint .adyen-checkout__card__cvc__hint--front .adyen-checkout__card__cvc__hint__location,
.adyen-checkout__label--focused .adyen-checkout__field__cvc--back-hint .adyen-checkout__card__cvc__hint--back .adyen-checkout__card__cvc__hint__location {
  animation-duration: 1s;
  animation-name: cvcIndicateLocation;
  animation-iteration-count: infinite;
  animation-direction: alternate; }

.adyen-checkout__card__cvc__hint__wrapper {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 27px;
  display: flex;
  align-items: center;
  margin: 0 10px;
  transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transform-origin: center;
  transform-style: preserve-3d;
  will-change: transform;
  backface-visibility: visible;
  transform: translate3d(0, 0, 0); }

.adyen-checkout__field__cvc--front-hint.adyen-checkout__card__cvc__hint__wrapper {
  transform: rotateY(180deg); }

.adyen-checkout__card__cvc__hint {
  backface-visibility: hidden;
  position: absolute;
  transition: opacity 0.1s linear; }

.adyen-checkout__field__exp-date_hint_wrapper {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  transition: opacity 0.1s linear; }
  .adyen-checkout__field__exp-date_hint_wrapper.adyen-checkout__field__exp-date_hint_wrapper--hidden {
    opacity: 0; }

.adyen-checkout__field__exp-date_hint {
  width: 27px;
  height: 18px;
  margin: 0 10px 0 0; }

.adyen-checkout__card__cvc__hint--front {
  transform: rotateY(180deg); }

@media (prefers-reduced-motion: reduce) {
  .adyen-checkout__card__cvc__hint__wrapper {
    transition: none; } }

.adyen-checkout__fieldset--revolving-plan .adyen-checkout__fieldset__fields {
  justify-content: left; }

.adyen-checkout__fieldset--revolving-plan .adyen-checkout__radio_group {
  display: flex;
  flex-direction: column; }

.adyen-checkout__fieldset--revolving-plan .adyen-checkout__radio_group__input-wrapper {
  margin-top: 20px; }

.adyen-checkout__fieldset--revolving-plan .adyen-checkout__field--revolving-plan-installments {
  position: relative;
  top: 42px;
  width: 30%;
  margin-left: 15px; }
