.adyen-checkout-ctp__loading-image {
  display: block;
  margin: 30px auto auto; }

.adyen-checkout-ctp__loading-subtitle {
  font-size: 16px;
  line-height: 19px;
  max-width: 280px;
  text-align: center;
  margin: 0 auto 58px; }
