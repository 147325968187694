.adyen_checkout-ctp__brand-wrapper {
  display: flex;
  align-items: center;
  height: 18px; }

.adyen_checkout-ctp__brand-logo {
  width: 24px;
  margin-right: 6px; }

.adyen_checkout-ctp__brand-pipe {
  height: 15px;
  margin-right: 6px; }

.adyen_checkout-ctp__brand-scheme {
  object-fit: none;
  margin-right: 6px; }
  .adyen_checkout-ctp__brand-scheme-mc {
    width: 27px; }
  .adyen_checkout-ctp__brand-scheme-visa {
    width: 35px; }
